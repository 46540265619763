import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import Layout from '../components/layout/layout'
import Title from '../components/global/title'
import QuoteForm from '../components/forms/quote-form'
import SEO from '../components/seo'

// Services
import { checkUser } from '../services/auth'

const QuotePage = ({ location }) => {
  useEffect(() => {
    const checkUserAuthentication = async () => {
      const user = await checkUser()
      if (user) navigate('/users/home')
    }
    checkUserAuthentication()
  }, [])

  const params = new URLSearchParams(location.search)
  const referralCode = params.get('refCode')

  return (
    <Layout>
      <SEO title="Log in" />
      <Title
        main={'Cotizador'}
        subTitle={['Te conseguiré un precio increíble en segundos']}
        options={{ center: true }}
      />
      <QuoteForm refCode={referralCode} />
    </Layout>
  )
}

export default QuotePage
