import React, { Component } from 'react'
import { navigate } from 'gatsby'

// Material UI
import MenuItem from '@material-ui/core/MenuItem'

//Components
import DatePicker from '../global/date-picker'
import CountrySelect from '../global/country-select'
import Select from '../global/select'
import GenderInput from '../global/gender-input'
import Input from './input'

// Helpers
import { CalculateBMI } from '../../helpers/calculate-bmi'

// Modals
import { EventEmitter } from '../../services/events'

// Validators
import validate from 'validate.js'
import quoteValidator from './validators/quote'

export default class QuoteForm extends Component {
  state = {
    gender: '',
    dob: '',
    height: '',
    weight: '',
    residence: '',
    errors: {},
    occupation: '',
    occupations: [
      'Actor de cine o teatro',
      'Abogado',
      'Administrador de oficina',
      'Administrador público',
      'Agente comercial',
      'Agente de aduanas',
      'Agente de relaciones públicas',
      'Agente de seguros y reaseguros',
      'Agente de viajes',
      'Agente inmobiliario',
      'Albañil',
      'Almacenero',
      'Ama de casa',
      'Analista financiero',
      'Arqueólogo',
      'Arquitecto',
      'Artista',
      'Auditor de negocios',
      'Autónomo',
      'Bibliotecario',
      'Biólogo marino',
      'Botánico',
      'Cajero de banco y afines',
      'Camarero',
      'Cantante',
      'Carpintero',
      'Chef',
      'Cocinero',
      'Comerciante de carbón',
      'Comerciante de construcción',
      'Comerciante de cueros y/o textiles',
      'Comerciante de frutas y alimentos',
      'Comerciante de ganado',
      'Comerciante de licores y bebidas',
      'Compositor',
      'Conductor de automóvil, taxi o furgoneta',
      'Conductor de motocicleta',
      'Confeccionista',
      'Conserje de edificio',
      'Constructor sin operar maquinaria pesada',
      'Consultor de negocios',
      'Contador',
      'Cosmetólogo',
      'Cuidador de animales',
      'Cura',
      'Detective privado',
      'Diseñador de interiores',
      'Diseñador gráfico',
      'Economista',
      'Electricista sin alto voltaje',
      'Empleado de estación de servicio',
      'Empleado de oficina',
      'Empleado doméstico',
      'Encuestador',
      'Enfermero',
      'Enólogo',
      'Escritor',
      'Escultor',
      'Estilista',
      'Estudiante',
      'Farmaceuta',
      'Financista',
      'Físico y austrónomo',
      'Fisioterapeuta',
      'Fontanero',
      'Fotógrafo',
      'Gerente comercial',
      'Gerente de almacén',
      'Gerente de clínicas y centros médicos',
      'Gerente de logística',
      'Gerente de oficina',
      'Granjero',
      'Guía turístico',
      'Historiador',
      'Hostelero',
      'Importador o exportador',
      'Ingeniero agrónomo',
      'Ingeniero de sonido',
      'Ingeniero eléctrico',
      'Ingeniero de sistemas',
      'Ingeniero de telecomunicaciones',
      'Ingeniero industrial o producción (excluye gas y petróleo)',
      'Ingeniero informático',
      'Ingeniero mecánico',
      'Instructor de baile',
      'Instructor de manejo',
      'Intérprete',
      'Investigador de laboratorio y desarrollo médico',
      'Jardinero',
      'Joyero',
      'Jubilado',
      'Juez',
      'Locutor de radio o televisión',
      'Maestro',
      'Magistrado',
      'Manufactura de elementos de computación',
      'Manufactura de equipos domésticos, línea blanca, etc',
      'Manufactura de instrumentos científicos y similares',
      'Manufactura de medicamentos',
      'Manufactura de mercadería eléctrica y electrónica',
      'Manufactura de mercaderías ópticas',
      'Manufactura de muebles y afines',
      'Manufactura de porcelanas',
      'Manufactura en la industria de comestibles y bebidas',
      'Manufactura metalúrgica',
      'Manufactura textil',
      'Manufacturas en general (sólo trabajadores calificados y semi-calificados)',
      'Matemático y actuario',
      'Mecánico de taller',
      'Médico especialista',
      'Médico cirujano',
      'Médico veterinario',
      'Meteorólogo',
      'Músico',
      'Obispo',
      'Odontólogo',
      'Operario de imprenta sin utilizar prensas',
      'Organizador de conferencias y eventos',
      'Paisajista',
      'Panadero',
      'Pastelero',
      'Pastor',
      'Peluquero',
      'Periodista',
      'Perito o ajustador de seguros',
      'Personal de limpieza liviana',
      'Personal de teatro',
      'Pintor en baja altura',
      'Profesional de la salud',
      'Profesor',
      'Programador informático',
      'Psicólogo',
      'Psiquiatra',
      'Publicista',
      'Recepcionista',
      'Recursos humanos',
      'Repartidor',
      'Restaurador',
      'Sacerdote',
      'Sociólogo',
      'Suscriptor de seguros o reaseguros',
      'Tambero (ordeñador)',
      'Tapicero',
      'Técnico de telecomunicación',
      'Técnico informático',
      'Telefonistas',
      'Teleoperadores',
      'Trabajador agrícola',
      'Trabajador en almacenes y supermercados',
      'Trabajador en blindaje de automóviles',
      'Trabajador social',
      'Traductor',
      'Urbanista',
      'Vendedor a domicilio',
      'Vendedor comercial',
      'Vendedor en línea o ecommerce',
      'Vendedor en mercadeo digital',
      'Vendedor en mercados públicos',
      'Vendedor en tiendas o almacenes',
      'Zapatero y afines',
    ].map(o => (
      <MenuItem key={o} value={o}>
        {o}
      </MenuItem>
    )),
    heights: Array.from({ length: 70 }, (v, k) => (
      <MenuItem key={k} value={150 + k + 1}>{`${150 + k + 1} cm`}</MenuItem>
    )),
    weights: Array.from({ length: 150 }, (v, k) => (
      <MenuItem key={k} value={50 + k + 1}>
        {' '}
        {`${50 + k + 1} kg`}
      </MenuItem>
    )),
    yesNo: Array.from({ length: 2 }, (v, k) => {
      return k === 0 ? (
        <MenuItem key={k} value={true}>
          Si
        </MenuItem>
      ) : (
        <MenuItem key={k} value={false}>
          No
        </MenuItem>
      )
    }),
  }

  /**
   * Handle input change
   * @param {Object} event
   */
  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  /**
   * Validates input values onBlur event
   */
  validateInput = async event => {
    const target = event.target
    const value = target.value
    const name = target.name
    const validationError = validate.single(value, quoteValidator[name])
    if (validationError && validationError.length) {
      this.setState({
        errors: { ...this.state.errors, [name]: [validationError[0]] },
      })
    } else {
      this.setState({ errors: { ...this.state.errors, [name]: [] } })
    }
  }

  /**
   * Handles date change
   * @param {Date} date
   */
  handleDateChange = date => {
    this.setState({ dob: date, errors: { dob: [] } })
  }

  /**
   * Handles autocomplete value
   * @param {String} country - Selected country
   */
  handleCountrySelect = country => {
    this.setState({ residence: country })
  }

  /**
   * Submit forms
   * @param {Object} event
   */
  handleSubmit = event => {
    event.preventDefault()
    const { dob, residence, height, weight, gender, occupation } = this.state
    const validationErrors = validate(
      { dob, residence, height, weight, gender, occupation },
      quoteValidator,
      { fullMessages: false }
    )
    if (validationErrors)
      return this.setState({
        errors: { ...this.state.errors, ...validationErrors },
      })
    const currDate = new Date()
    const diff = currDate - dob
    const age = Math.floor(diff / 31557600000)
    const bmi = CalculateBMI({ weight, height })
    if (bmi > 40) {
      EventEmitter.dispatch('toggleModal', {
        show: true,
        success: false,
        title: '¡Lo sentimos!',
        message:
          'Tu BMI esta por fuera de nuestros limites por lo que no podemos proveerte una cotización.',
        navigateTo: '/',
      })
      return false
    }
    console.log('props', this.props)
    return navigate('/plans', {
      state: {
        quote: true,
        age,
        quoteData: { dob, residence, height, weight, gender },
        refCode: this.props.refCode,
      },
    })
  }

  render() {
    return (
      <div className="container mx-auto mt-0 text-slide-in">
        <div className="grid grid-cols-12">
          <div className=" col-start-2 col-span-10 lg:col-start-5 lg:col-span-4 text-center">
            <form onSubmit={this.handleSubmit}>
              <GenderInput
                name={'gender'}
                errors={this.state.errors['gender']}
                onChange={this.handleInputChange}
              />
              <div className="form-input date-input">
                <DatePicker
                  placeholder={'Fecha nacimiento (dd/MM/aaaa)'}
                  selectedDate={this.state.dob}
                  errors={this.state.errors['dob']}
                  onChange={this.handleDateChange}
                />
              </div>
              <div className="form-input mt-3">
                <Input
                  name="height"
                  errors={this.state.errors['height']}
                  type="number"
                  placeholder="Dime tu altura (cm)"
                  value={this.state.height}
                  onChange={this.handleInputChange}
                  onBlur={this.validateInput}
                />
              </div>
              <div className="form-input">
                <Input
                  name="weight"
                  errors={this.state.errors['weight']}
                  type="number"
                  placeholder="Dime tu peso (kg)"
                  value={this.state.weight}
                  onChange={this.handleInputChange}
                  onBlur={this.validateInput}
                />
              </div>
              <CountrySelect
                name={'residence'}
                errors={this.state.errors['residence']}
                onBlur={this.validateInput}
                value={this.state.residence}
                onChange={this.handleCountrySelect}
              />
              <Select
                name="occupation"
                onBlur={this.validateInput}
                value={this.state.occupation}
                onChange={this.handleInputChange}
                placeholder="¿Ocupación?"
                errors={this.state.errors['occupation']}
                items={this.state.occupations}
              />
              <div className="grid grid-cols-12">
                <div className="col-span-12">
                  <button
                    className="default-button w-full mb-8 py-3 px-6 mt-1"
                    type="submit"
                  >
                    Cotizar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
