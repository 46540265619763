/**
 * Calculates user's BMI
 * @param {Object} request
 * @param {Number} request.weight - User's weight
 * @param {Number} request.height - User's height
 */
export const CalculateBMI = ({ weight = 0, height = 0 }) => {
  if (!weight || !height) return
  try {
    if (weight && isNaN(weight)) weight = parseInt(weight)
    if (height && isNaN(height)) height = parseInt(height)
    const mt = height / 100
    const BMI = weight / Math.pow(mt, 2)
    return BMI
  } catch (e) {
    return { success: false }
  }
}