import React from 'react'

// Components
import Error from './error'
import componentStyles from "./gender-input.module.css"

const GenderInput = ({ name, onChange, onBlur, errors }) => (
    <>
        <div className="form-input flex justify-center align-center mb-4">
            <label htmlFor="gender" className="mx-3 text-lg">
                <input
                    className={componentStyles.radio}
                    type={"radio"}
                    name={name}
                    value="M"
                    onChange={onChange}
                    onBlur={onBlur}
                />
            Hombre
            </label>
            <label htmlFor="gender" className="mx-3 text-lg">
                <input
                    className={componentStyles.radio}
                    type={"radio"}
                    name={name}
                    value="F"
                    onChange={onChange}
                    onBlur={onBlur}
                />
                Mujer
            </label>
        </div>
        {errors && (
            errors.map((e, i) => <Error center key={i} index={i} message={e} />))}
    </>
)

export default GenderInput