export default {
  dob: {
    presence: { allowEmpty: false, message: "Campo requerido." }
  },
  height: {
    presence: { allowEmpty: false, message: "Campo requerido." },
    numericality: {
      onlyInteger: true,
      message: "Debes introducir una altura válida. Ej: 175"
    }
  },
  weight: {
    presence: { allowEmpty: false, message: "Campo requerido." },
    numericality: {
      onlyInteger: true,
      message: "Debes introducir un peso válido."
    }
  },
  residence: {
    presence: { allowEmpty: false, message: "Debes especificar tu país de residencia." },
  },
  gender: {
    presence: { allowEmpty: false, message: "Campo requerido." },
  },
  occupation: {
    presence: { allowEmpty: false, message: "Campo requerido." }
  }
}