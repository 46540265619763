import React from 'react'
import countryLib from "i18n-iso-countries"

// Material UI
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

// Components
import Error from './error'

// Libs
import classnames from 'classnames'

const CountrySelect = ({ name, value, onChange, errors, onBlur }) => {
  countryLib.registerLocale(require("i18n-iso-countries/langs/es.json"))
  if (!value) value = "¿Cuál es tu país de residencia?"
  let countries = []
  let latamCodes = ['AR', 'BO', 'CL', 'CO', 'CR', 'EC', 'ES', 'SV', 'GT', 'HN', 'MX', 'DO', 'NI', 'PA', 'PY', 'PE', 'UY', 'VE'].sort()
  const rawCountries = countryLib.getNames("es")
  for (let country in rawCountries) { if (latamCodes.includes(country)) countries.push({ code: country, fullName: rawCountries[country] }) }
  let classes = classnames("form-input", "select-input", { "error": errors && errors.length })
  function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined'
      ? isoCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
      : isoCode;
  }
  return (
    <>
      <Autocomplete
        options={countries}
        autoHighlight
        autoSelect
        className={classes}
        onChange={(e, value) => value ? onChange(value.fullName) : false}
        getOptionLabel={(option) => option.fullName}
        renderOption={(option) => (
          <React.Fragment>
            <span>{countryToFlag(option.code)}</span>
            {option.fullName} ({option.code})
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            name={name}
            {...params}
            placeholder="¿Cuál es tu país de residencia?"
            variant="standard"
            inputProps={{
              ...params.inputProps,
              style: { margin: 0 },
            }}
          />
        )}
      />
      {errors && (
        errors.map((e, i) => <Error key={i} index={i} message={e} />))}
    </>
  )
}

export default CountrySelect